<template>
  <vx-card title="Payment Proposal">
    <vs-tabs alignment="center">
      <vs-tab label="Purchase Invoice">
        <vs-tabs>
          <vs-tab label="Valid Invoice">
            <open></open>
          </vs-tab>
          <vs-tab label="Pending">
            <div class="tab-text">
              <pending></pending>
            </div>
          </vs-tab>
          <vs-tab label="Approved">
            <div class="tab-text">
              <approved></approved>
            </div>
          </vs-tab>
          <vs-tab label="Paid">
            <div class="tab-text">
              <closed po_type="all"></closed>
            </div>
          </vs-tab>
          <vs-tab label="Rejected">
            <div class="tab-text">
              <rejected></rejected>
            </div>
          </vs-tab> </vs-tabs
      ></vs-tab>
      <vs-tab label="Credit Note Supplier">
        <vs-tabs>
          <vs-tab label="Valid Credit Note">
            <credit-note-supplier></credit-note-supplier>
          </vs-tab>
          <vs-tab label="Pending">
            <div class="tab-text">
              <processing></processing>
            </div>
          </vs-tab>
          <vs-tab label="Approved">
            <div class="tab-text">
              <processed></processed>
            </div>
          </vs-tab>
          <vs-tab label="Paid">
            <div class="tab-text">
              <credit-note-paid></credit-note-paid>
            </div>
          </vs-tab>
          <vs-tab label="Rejected">
            <div class="tab-text">
              <credit-note-rejected></credit-note-rejected>
            </div>
          </vs-tab>
        </vs-tabs>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import Open from "./open";
import Pending from "./pending";
import Approved from "./approved";
import WaitingPayment from "./waiting_payment";
import Closed from "./closed";
import Payment from "./payment";
import Rejected from "./rejected/rejected.vue";
import CreditNoteSupplier from "./cn-supplier/open/index.vue";
import Processing from "./cn-supplier/processing/index.vue";
import Processed from "./cn-supplier/processed/index.vue";
import CreditNotePaid from "./cn-supplier/paid/index.vue";
import CreditNoteRejected from "./cn-supplier/rejected/index.vue";
export default {
  components: {
    Open,
    Pending,
    Approved,
    Closed,
    WaitingPayment,
    Payment,
    Rejected,
    "credit-note-supplier": CreditNoteSupplier,
    processing: Processing,
    processed: Processed,
    "credit-note-paid": CreditNotePaid,
    "credit-note-rejected": CreditNoteRejected,
  },
  async mounted() {
    await this.$store.dispatch("user/getPermissions", "payment-proposal");
  },
};
</script>
